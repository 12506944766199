import
{
    TransactionCardProps,
    TransactionCardVariant,
    VisitTypes
} from 'components/transaction-card/transaction-card.types';

export const mockVisitHistoryCards: TransactionCardProps[] = [
    {
        transactionInfo: {
            name: 'COVID: SARS-CoV-2',
            transactionType: VisitTypes.CHAT,
            date: '07/12/2024 12:00:00 AM',
            epostPatientNum: '12345',
            status: 'REJECTED'
        },
        iconType: 'chat',
        user: 'Leticia',
        hasDetails: true,
        variant: TransactionCardVariant.HISTORY
    },
    {
        transactionInfo: {
            name: 'High Cholesterol',
            transactionType: VisitTypes.VIDEO_CHAT,
            date: '07/12/2024 12:00:00 AM',
            epostPatientNum: '12346',
            status: 'WAITING FOR PATIENT'
        },
        iconType: 'video-chat',
        user: 'Leticia',
        hasDetails: false,
        variant: TransactionCardVariant.HISTORY
    },
    {
        transactionInfo: {
            name: 'Hair Loss',
            transactionType: VisitTypes.VIDEO,
            date: '07/12/2024 12:00:00 AM',
            epostPatientNum: '12347',
            status: 'COMPLETED'
        },
        iconType: 'video',
        user: 'Leticia',
        hasDetails: true,
        variant: TransactionCardVariant.HISTORY
    },
    {
        transactionInfo: {
            name: 'Skincare',
            transactionType: VisitTypes.VIDEO_CHAT,
            date: '07/12/2024 12:00:00 AM',
            epostPatientNum: '12348',
            status: 'CANCELED'
        },
        iconType: 'video-chat',
        user: 'Leticia',
        hasDetails: false,
        variant: TransactionCardVariant.HISTORY,
    },
    {
        transactionInfo: {
            name: 'UTI',
            transactionType: VisitTypes.VIDEO,
            date: '07/12/2024 12:00:00 AM',
            epostPatientNum: '12340',
            status: 'REFERRED OUT'
        },
        iconType: 'video',
        user: 'Leticia',
        hasDetails: true,
        variant: TransactionCardVariant.HISTORY,
        userType: 'caregiver'
    },
    {
        transactionInfo: {
            name: 'Skincare',
            transactionType: VisitTypes.VIDEO_CHAT,
            date: '08/12/2024 12:00:00 AM',
            epostPatientNum: '12348',
            status: 'CANCELED'
        },
        iconType: 'video-chat',
        user: 'Leticia',
        hasDetails: false,
        variant: TransactionCardVariant.HISTORY,
    },
    {
        transactionInfo: {
            name: 'UTI',
            transactionType: VisitTypes.VIDEO,
            date: '08/12/2024 12:00:00 AM',
            epostPatientNum: '12340',
            status: 'REFERRED OUT'
        },
        iconType: 'video',
        user: 'Leticia',
        hasDetails: true,
        variant: TransactionCardVariant.HISTORY,
        userType: 'caregiver'
    },
    {
        transactionInfo: {
            name: 'Hair Loss',
            transactionType: VisitTypes.VIDEO,
            date: '09/4/2024 12:00:00 AM',
            epostPatientNum: '12347',
            status: 'COMPLETED'
        },
        iconType: 'video',
        user: 'Leticia',
        hasDetails: true,
        variant: TransactionCardVariant.HISTORY
    },
];

export const mockActiveVisitCards: TransactionCardProps[] = [
    {
        transactionInfo: {
            name: 'UTI',
            transactionType: VisitTypes.VIDEO,
            date: '08/12/2024 12:00:00 AM',
            epostPatientNum: '132345',
            status: 'INCOMPLETE'
        },
        iconType: 'video',
        user: 'Leticia',
        hasDetails: true,
        subIconType: 'error',
        variant: TransactionCardVariant.HISTORY,
    },
    {
        transactionInfo: {
            name: 'UTI',
            transactionType: VisitTypes.VIDEO,
            date: '08/12/2024 12:00:00 AM',
            epostPatientNum: '1312345',
            status: 'BOOKED'
        },
        iconType: 'video',
        user: 'Leticia',
        subIconType: 'check',
        hasDetails: true,
        variant: TransactionCardVariant.HISTORY,
    }
]
